import { createStore } from "vuex";

const removerAcentos = (string) => {
  const mapaAcentosHex = {
    a: /[\xE0-\xE6]/g,
    e: /[\xE8-\xEB]/g,
    i: /[\xEC-\xEF]/g,
    o: /[\xF2-\xF6]/g,
    u: /[\xF9-\xFC]/g,
    c: /\xE7/g,
  };
  for (let letra in mapaAcentosHex) {
    var expressaoRegular = mapaAcentosHex[letra];
    string = string.replace(expressaoRegular, letra);
  }
  return string;
};

export default createStore({
  state: {
    dekoracje: [
      {
        title: "Metalowa Róża",
        src: require("@/assets/img/dekoracje/1.jpeg"),
        price: 80,
        description:
          "Chcesz aby kwiaty, które kupisz nigdy nie zwiędły? W takim razie ta pozycja jest dla Ciebie! To róże wykonane w pełni z metalu i nigdy nie więdną (i rdzewieją)! Idealnie na prezent, bukiet pogrzebowy lub ozdobę do domu! Dostępne w różnych wzorach kolorach na zamówienie.",
        type: "Top",
        color: "Black",
        brand: "Wilczak Spawalogy",
        gender: "dekoracje",
        id: 1,
      },
      {
        title: "Smok",
        src: require("@/assets/img/dekoracje/2.jpeg"),
        price: 500,
        description:
          "Chcesz aby Twoim gościom opadły szczęki? Ten smok sieje istny postrach zwłaszcza jak stoi na jakimś dachu. W pełni skompletowana i zespawana figurka smoka, która ma niesamowite rozmiary. Polecane jako ozdoba do ogrodu.",
        type: "Top",
        color: "Grey",
        brand: "Wilczak Spawalogy",
        gender: "dekoracje",
        id: 2,
      },
    ],
    projekty: [
      {
        title: "Piec Koza",
        src: require("@/assets/img/projekty/1.jpeg"),
        price: 300,
        description:
          "Kiedy nadchodzi zima, a nie masz jak się ogrzać za uczciwą cenę, w tedy na pomoc przychodzi Piec Koza Na Zimnego Mroza! Ten zbudowany ze starego boilera z dołączoną dedykowaną parą drzwiczek i kominem bez problemu ogrzeje całe pomieszczenie! Dostępny w różnych kolorach na zamówanie. ",
        type: "Top",
        color: "Black",
        brand: "Wilczak Spawalogy",
        gender: "projekty",
        id: 1,
      },
      {
        title: "Buty Ricka Astley'a",
        src: require("@/assets/img/projekty/2.jpeg"),
        price: 779.99,
        description:
          "Niezwykle stylowe i szykowne buty, które nosił sam Rick Astley podczas nagrywania jego największego przeboju muzycznego. Poczuj się jak on i zatańcz ikoniczną solówę już za jedyne 779,99zł.",
        type: "Shoes",
        color: "Black",
        brand: "Rick Astley Collection",
        gender: "projekty",
        id: 2,
      },
    ],
    cart: [],
    product: {},
    searchWord: null,
    filteredProducts: null,
    tax: 23,
    shipping: 5,
  },
  getters: {
    allProducts: (state) => state.dekoracje.concat(state.projekty),
    getWomen: (state) => state.dekoracje,
    getMen: (state) => state.projekty,

    getCart: (state) => state.cart,
    cartItemCount: (state) => state.cart.length,

    getSearchWord: (state) => state.searchWord,

    getFilteredProduct: (state) => state.filteredProducts,

    getProductByIdWomen: (state) => (id) =>
      state.dekoracje.find((dekoracje) => dekoracje.id === parseInt(id)),

    getProductByIdMen: (state) => (id) =>
      state.projekty.find((projekty) => projekty.id === parseInt(id)),

    cartTotalPrice: (state) => {
      let total = 0;
      state.cart.forEach((item) => {
        total += item.quantityPrice;
      });
      return total.toFixed(2);
    },

    getTax: (state) => state.tax,
    getShipping: (state) => state.shipping,

    cartCheckoutPrice: (state) => {
      let total = 0;

      state.cart.forEach((item) => {
        total = item.quantityPrice + state.shipping;
        total = total + (total * state.tax) / 100;
      });
      return total.toFixed(2);
    },
  },
  mutations: {
    ADD_TO_CART: (state, { product, quantity, size, quantityPrice }) => {
      let productInCart = state.cart.find((item) => {
        return item.product.id === product.id;
      });
      if (productInCart) {
        productInCart.quantity += quantity;
        productInCart.quantityPrice += productInCart.product.price;
        productInCart.quantityPrice =
          Math.round(productInCart.quantityPrice * 100) / 100;
        return;
      }
      state.cart.push({ product, quantity, size, quantityPrice });
    },

    REMOVE_FROM_CART: (state, product) => {
      state.cart = state.cart.filter((item) => {
        return item.product.id !== product.id;
      });
    },

    GET_PRODUCT_DETAILS: (state, product) => {
      state.productDetails.push(product);
    },

    FILTERED_PRODUCTS(state, word) {
      if (!word || word === "{}") {
        state.searchWord = null;
        state.filteredProducts = null;
      } else {
        state.searchWord = word;
        word = removerAcentos(word.trim().toLowerCase());
        state.filteredProducts = state.dekoracje
          .concat(state.projekty)
          .filter((product) => {
            return (
              product.title.toLowerCase().includes(word) ||
              product.brand.toLowerCase().includes(word) ||
              product.color.toLowerCase().includes(word) ||
              product.type.toLowerCase().includes(word)
            );
          });
      }
    },

    ORDER_SUCCESSFUL(state) {
      state.cart = [];
    },
  },
  actions: {
    addProductToCart: (
      { commit },
      { product, quantity, size, quantityPrice }
    ) => {
      commit("ADD_TO_CART", { product, quantity, size, quantityPrice });
    },
    removeProductFromCart: ({ commit }, product) => {
      commit("REMOVE_FROM_CART", product);
    },
    filterProducts({ commit }, word) {
      commit("FILTERED_PRODUCTS", word);
    },
    orderSuccess({ commit }) {
      commit("ORDER_SUCCESSFUL");
    },
  },
  modules: {},
});
